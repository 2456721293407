.header-box{
    max-width: 1980px;
    margin: 0 auto;
    height: 60px;
    background-color: #fff;
    box-shadow: 0 2px 8px #f0f1f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    .active-menu {
        color: #1DA57A;
    }
    .left-box{
        flex: 1;
        padding-top: 20px;
    }

}
.logo-box{
    width: 120px;
    height: 45px;
    display: inline-block;
    margin-right: 15px;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}
@primary-color: #1DA57A;