.account-footer {
    text-align: center;
}

.cursors {
    cursor: pointer;
}

.default-color {
    color: #1DA57A;
}

.black-color {
    color: #333333;
}

.search-box {
    width: 400px;
    margin-bottom: 15px;
}

.account-page,
.company-page {
    max-width: 1980px;
    padding: 16px 38px;
    margin: 0 auto;
}

.m-r-16 {
    margin-right: 16px;
}

.m-lr-8 {
    margin-left: 8px;
    margin-right: 8px;
}

.company-tool-bar {
    display: flex;
    justify-content: space-between;
}

.edite-page {
    max-width: 980px;
    margin: 0 auto;
}

.edite-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 81px;
}

.chooseBtn {
    color: #00B578;
    cursor: pointer;
}

.edite-box-item-left {
    width: 200px;
}

.edite-box-item-right {
    width: 350px;
}

.address-local-box {
    display: flex;
}

.address-item {
    width: 300px;
    margin-right: 16px;
}

.title-blod {
    font-size: 16px;
    font-weight: 600;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #2c2c2c;
    margin: 10px 0;
}

.article-item-box {
    margin-bottom: 40px;
}

.form-footer-box {
    text-align: center;
    padding-bottom: 50px;
}

.login-page {
    display: flex;
    align-items: center;
}

.login-bar-box {
    width: 100%;
    height: 300px;
    background-color: #1DA57A;
}

.login-form-box {
    width: 400px;
    height: 300px;
    background-color: #fff;
    box-shadow: 0 2px 8px #f0f1f4;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    border-radius: 8px;
}

.oms-box {
    text-align: center;
    position: relative;
    top: 40px;
    font-size: 16px;
    font-weight: 600;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #2c2c2c;
}

.oms-box::before {
    content: '';
    width: 100px;
    height: 2px;
    border-bottom: 1px solid #e4e4e4;
    position: absolute;
    top: 10px;
    left: 40px;
}

.oms-box::after {
    content: '';
    width: 100px;
    height: 2px;
    border-bottom: 1px solid #e4e4e4;
    position: absolute;
    top: 10px;
    right: 40px;
}

.oms-form-sub-box {
    padding: 0 50px;
    margin-top: 90px;
}

.login-form-btn {
    margin-left: 58px;
}

.pagination-box {
    text-align: right;
    padding-top: 20px;
}

.company-logo-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.company-logos {
    width: 64px;
    height: 64px;
    margin-left: auto;
    margin-right: auto;
}

.company-logo-list .company-logo-list-item {
    width: 110px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: #000000;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;
}

.company-logo-title {
    /*  padding-left: 20px; */
}

.company-logo-box {
    text-align: center;
    padding-top: 20px;
}

.company-logo-btn {
    border: 0;
    outline: none;
    background-color: #1DA57A;
    color: #fff;
    cursor: pointer;
    width: 100px;
    height: 30px;
    line-height: 30px;
    border-radius: 30px;
}